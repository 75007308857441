import React from "react";
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Link } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";

const SuccessPage = () => (
  <Layout>
    <SEO title="Success" />
    <Container fluid>
      <Row className="bg-theme py-5">
        <Col className="text-center">
          <h1>SUBMISSION SUCCESSFUL</h1>
          <p>Thank you for your enquiry. We will get back to you at the soonest!</p>
          <p className="lead">
            <strong>
              <Link to="/" className="text-reset">&lt; Back to Homepage</Link>
            </strong>
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default SuccessPage;
